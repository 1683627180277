import {
    pages, calculators, faq, news, blog, comments, users,
    examples, banners, neouronet, redirects, dashboards, fileManager, reviews, directory,
} from './navigationData';

const BASE_URL = process.env.ADMIN_API_BASE_URL || process.env.VUE_APP_PROJECT_URL;

const projects = {
    com: {
        title: 'Admin | Zaochnik.com',
        baseURL: BASE_URL,
        routes: ['banners', 'dashboards', 'faq', 'calculators', 'comments',
            'neouronet', 'news', 'reviews', 'users', 'examples', 'redirects', 'directory', 'pages', 'fileManager'],
        navigation: [
            banners,
            dashboards,
            faq,
            calculators,
            comments,
            neouronet,
            news,
            reviews,
            users,
            examples,
            redirects,
            directory,
            pages,
            fileManager,
        ],
    },
    ru: {
        title: 'Admin | Zaochnik.ru',
        baseURL: BASE_URL,
        routes: ['blog', 'faq', 'dashboards', 'news', 'reviews', 'users',
            'examples', 'redirects', 'pages', 'comments', 'fileManager'],
        navigation: [
            blog,
            dashboards,
            faq,
            comments,
            news,
            reviews,
            users,
            examples,
            redirects,
            pages,
            fileManager,
            // banners,
        ],
    },
};

function loadProject() {
    return projects[process.env.VUE_APP_PROJECT];
}

export default loadProject;
