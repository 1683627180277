<template>
    <AppButton
        :loading="isLoading"
        :type="type"
        @click.prevent="handleSubmit"
    >
        <span
            v-if="operationSuccess && isSuccess"
            class="button__icon"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
            >
                <circle
                    cx="256"
                    cy="256"
                    r="225"
                    fill="none"
                    stroke="white"
                    stroke-width="50"
                    stroke-dasharray="1320"
                    stroke-dashoffset="1320"
                    width="100%"
                    height="100%"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                >
                    <animate
                        attributeName="stroke-dashoffset"
                        begin="0"
                        dur=".6s"
                        to="251.2"
                        fill="freeze"
                    />
                </circle>
                <path
                    d="M135.7,253.787 l76.8,76.8 l253.787,-253.787"
                    fill="none"
                    stroke="white"
                    stroke-width="50"
                    stroke-dasharray="1000"
                    stroke-dashoffset="1000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                >
                    <animate
                        attributeName="stroke-dashoffset"
                        begin=".6s"
                        dur=".6s"
                        to="0"
                        fill="freeze"
                    />
                </path>
            </svg>
        </span>

        <slot />
    </AppButton>
</template>

<script>
export default {
    name: 'AppButtonProgress',
    props: {
        submitForm: {
            type: [Function, Promise],
            required: true,
        },
        routerName: {
            type: String,
            default: undefined,
        },
        page: {
            type: [Number, String],
            default: 1,
        },
        paramsRouter: {
            type: Object,
            default: null,
        },
        isInvalid: {
            type: Boolean,
            default: false,
        },
        timeRouter: {
            type: Number,
            default: 3000,
        },
        operationSuccess: {
            type: Boolean,
            default: true,
        },
        type: {
            type: String,
            default: 'button',
        },
    },

    data() {
        return {
            isLoading: false,
            isSuccess: false,
        };
    },

    methods: {
        async handleSubmit() {
            try {
                await this.submitForm();

                if (!this.isInvalid) {
                    this.isLoading = true;

                    setTimeout(() => {
                        this.isLoading = false;
                        if (this.operationSuccess) {
                            this.isSuccess = true;
                        }
                    }, 500);

                    setTimeout(() => {
                        if (this.routerName && this.operationSuccess) {
                            const routeData = { name: this.routerName, query: { page: this.page } };
                            if (this.paramsRouter !== null) {
                                routeData.params = this.paramsRouter;
                            }
                            this.$router.push(routeData);
                        }
                        this.isSuccess = false;
                    }, this.timeRouter);
                }
            } catch (error) {
                this.isLoading = false;
            }
        },
    },
};
</script>

<style lang="postcss">
.button_primary {
    position: relative;
    overflow: hidden;
    transition: background-color 0.6s ease;
    cursor: pointer;
}

/* .button_primary.success {
    background-color: var(--success-color);
    transition-delay: 1.2s;
} */

svg {
    width: 20px;
    height: 20px;
}
</style>
