import { createRouter, createWebHistory } from 'vue-router';
import loadProject from '../utils/projects';
import main from '../routes/main';
import auth from '../routes/auth';
import http from '../store/http';
import store from '../store/index';
import { getCookie } from '../utils/cookie';

const BASE_URL = store.state.auth.project;
const UNAUTHORIZED = 401;
const FORBIDDEN = 403;
const INTERNAL_SERVER_ERROR = 500;
const routersD = loadProject().routes;

const handleKnownErrors = (status, router) => {
    switch (status) {
    case INTERNAL_SERVER_ERROR:
        router.replace({ name: 'internalError' });
        break;
    case FORBIDDEN:
        router.replace({ name: 'PermissionDenied' });
        break;
    case UNAUTHORIZED:
        router.replace({ name: 'Enter' });
        store.commit('auth/clearAccess');
        break;
    case undefined:
        console.error('Received undefined status');
        // router.replace({ name: 'Enter' });
        // store.commit('auth/clearAccess');
        break;
    default:
        console.error('Server not responding');
    }
};

const getRouter = () => new Promise((resolve) => {
    const promises = routersD.map(r => import(`../routes/${r}.js`));

    Promise.all(promises).then((routeFiles) => {
        const routes = [...main, ...auth];
        routeFiles.forEach((routeFile) => {
            routes.push(...routeFile.default);
        });

        const router = createRouter({
            mode: 'history',
            history: createWebHistory(),
            routes,
        });

        router.beforeEach((to, from, next) => {
            if (to.matched.some(record => record.meta.authRequired)) {
                if (!getCookie('jwt_access')) {
                    next({ name: 'Enter', query: { redirect: to.fullPath } });
                    return;
                }
            }
            const defaultTitle = `Admin Zaochnik.${BASE_URL}`;
            const nearestWithTitle = to.matched.slice().reverse().find(r => r?.meta?.title);
            const titleMeta = nearestWithTitle ? `${nearestWithTitle?.meta?.title} | ${defaultTitle}` : defaultTitle;

            const { id } = to.params;

            if (titleMeta) {
                if (id) {
                    document.title = `${titleMeta}-${id}`;
                } else {
                    document.title = titleMeta;
                }
            }

            http.interceptors.response.use(
                response => response,
                async (error) => {
                    const { response: errorResponse = {}, config } = error;
                    const { status } = errorResponse;
                    const originalRequest = config;

                    if (status === UNAUTHORIZED) {
                        const isLoginAttempt = originalRequest.url === '/auth/'
                            && originalRequest.method === 'post';
                        const isRefresh = originalRequest.url === '/auth/' && originalRequest.method === 'patch';

                        if (!isLoginAttempt && !isRefresh && !originalRequest.isRetryAttempt) {
                            try {
                                await store.dispatch('auth/refresh');

                                originalRequest.isRetryAttempt = true;
                                originalRequest.headers.authorization = http.defaults.headers.common.authorization;
                                return http(originalRequest);
                            } catch (refreshError) {
                                return Promise.reject(refreshError);
                            }
                        }
                    }

                    handleKnownErrors(status, router);
                    return Promise.reject(error);
                },
            );

            next();
        });
        resolve(router);
    });
});

export default getRouter;
