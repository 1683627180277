<template>
    <a
        v-if="href"
        class="button"
        :href="href"
    >
        <slot />
    </a>
    <button
        v-else
        :class="['button', buttonClasses]"
        :type="type"
        :disabled="disabled || loading"
        :aria-label="ariaLabel"
    >
        <span
            v-show="loading"
            class="loader button__icon"
        />
        <slot />
    </button>
</template>

<script>
export default {
    name: 'AppButton',
    props: {
        type: {
            type: String,
            default: 'button',
        },
        href: {
            type: String,
            default: '',
        },
        loading: {
            type: Boolean,
            default: false,
        },
        danger: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        primary: {
            type: Boolean,
            default: true,
        },
        empty: {
            type: Boolean,
            default: false,
        },
        ariaLabel: {
            type: String,
            default: '',
        },
    },

    computed: {
        buttonClasses() {
            return {
                button_loading: this.loading,
                button_danger: this.danger,
                button_primary: this.primary,
                button_empty: this.empty,
            };
        },
    },
};
</script>

<style lang="postcss">
.button {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    /* min-width: 160px; */
    padding: 8px 28px;
    font-family: var(--font-primary);
    font-size: 12px;
    text-align: center;
    font-weight: 400;
    color: var(--text-color);
    background: transparent;
    border: 1px solid transparent;
    border-radius: 5px;
    cursor: pointer;
    transition: var(--transition-delay) ease-in-out;
    transition-property: background-color;
    min-height: 40px;

    @media (--mobile) {
        padding: 9px 24px;
        font-size: 13px;
    }

    &:focus {
        outline: none;
    }

    /* &:disabled {
        color: #999;
        background-color: #ddd;

        &:hover,
        &:focus {
            color: #999;
            background-color: #ddd;
            cursor: default;
        }
    } */

    &_wide {
        width: 100%;
    }

    &_small {
        padding: 8px 5px;
        font-size: 12px;
        font-weight: 400;

        @media (--desktop) {
            padding: 12px 5px;
            font-size: 13px;
            font-weight: 600;
        }
    }

    &_reset {
        min-width: auto;
        padding: 0;
        background-color: transparent;
        border: none;
        border-radius: 0;
        outline: none;
        -webkit-appearance: none;
    }

    &_icon {
        padding: 0;
        width: 40px;
        height: 40px;
        min-width: unset;
    }

    &_primary {
        color: #fff;
        background-color: var(--primary-color);

        &:hover,
        &:focus {
            background-color: var(--link-color);
            color: #fff;
        }

        /* &:disabled {
            color: #fff;
            background-color: var(--primary-color);
            opacity: .24;

            &:hover,
            &:focus {
                color: #fff;
                background-color: var(--primary-color);
                cursor: default;
            }
        } */
    }

    &_loading {
        /* color: transparent; */

        /* &:hover,
        &:focus,
        &:disabled {
            color: transparent;
        } */

        .icon {
            display: none;
        }
    }

    &_confirm {
        color: #fff;
        background-color: var(--success-secondary-color);

        &:hover,
        &:focus {
            background-color: var(--success-color);
        }
    }

    &_danger {
        color: #fff;
        background-color: var(--danger-secondary-color);

        &:hover,
        &:focus {
            background-color: var(--danger-color);
        }
    }

    &_empty {
        color: var(--text-secondary-color);
        background-color: transparent;
        border: 1px solid var(--separator-color);

        &:hover:not(.button_loading) {
            color: #fff;
            background-color: var(--primary-color);
            border-color: var(--primary-color);
        }
    }

    &_remove {
        width: 190px;
        margin-left: auto;

        & svg {
            fill: #fff;
        }
    }

    &_clear {
        cursor: pointer;
        position: absolute;
        right: 16px;
        top: 14px;
        background: none;
        border: none;
    }

    &__arrow {
        min-width: 40px;
        min-height: 40px;
        height: 40px;
        width: 40px;
        padding: 0;

        &:first-child {
            margin-right: 10px;
        }

        & .icon {
            fill: #fff;
        }
    }

    & .loader {
        position: static;
        /* top: calc(50% - 10px);
        left: calc(50% - 10px); */
        border-width: 2px;
    }

    &__icon {
        margin-right: 10px;
        height: 20px;
    }
}
</style>
